import React, { useEffect, useState, useContext } from "react"
import queryString from "query-string"
import axios from "axios"
import { navigate } from "gatsby"
import { UiContext } from "../../context/UiContext"

import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { headlineThree, colors } from "../../Utilities"
import OrderCancelDetails from "../../components/cancel/OrderCancelDetails"

const Cancel = props => {
  const { state: uiState, dispatch: uiDispatch } = useContext(UiContext)
  const [orderConfirmedCancelled, setOrderConfirmedCancelled] = useState(true)

  useEffect(() => {
    const queryData = queryString.parse(props.location.search)
    const cancelPayment = async params => {
      uiDispatch({ type: "SERVER_LOAD" })
      if (Object.keys(params).length === 0 && typeof params === "object") {
        uiDispatch({ type: "SERVER_LOAD_FINISHED" })
        navigate("/shop", { replace: true })
        return
      }

      try {
        const res = await axios.post(
          `${process.env.GATSBY_API_URL}/orders/paypal/cancel`,
          {
            params,
          }
        )
        setOrderConfirmedCancelled(res.data)
        // Remove the query string from the url. //
        if (res.data) {
          navigate("/checkout/cancel", { replace: true })
        } else {
          navigate("/shop", { replace: true })
        }
        uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      } catch (err) {
        // Remove the spinner from the UI. //
        uiDispatch({ type: "SERVER_LOAD_FINISHED" })
        // Alert the user of the error. //
        uiDispatch({
          type: "ALERT_ERROR",
          payload: `${err.message}, Please try again later.`,
        })
      }
    }
    cancelPayment(queryData)
  }, [])
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={``}
        description={``}
        metaImg={``}
        location={props.location.pathname}
      />
      {orderConfirmedCancelled ? (
        <>
          <CancelHeader>
            <h2>Order Cancelled</h2>
          </CancelHeader>
          <OrderCancelDetails />
        </>
      ) : (
        <>
          <CancelHeader>
            <h2>Order Details</h2>
          </CancelHeader>
        </>
      )}
    </Layout>
  )
}

const CancelHeader = styled.div`
  padding: 1.5rem 3rem;
  background-color: ${colors.colorTertiary};
  text-align: center;

  h2 {
    ${headlineThree};
    color: ${colors.white};
    text-transform: uppercase;
  }
`

export default Cancel
