import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { bodyCopy, buttonOne, standardWrapper } from "../../Utilities"

const OrderCancelDetails = () => {
  return (
    <OrderCancelDetailsStyled>
      <div className="wrapper">
        <div className="cancelDetails">
          <p>Your transaction with PayPal has been cancelled</p>
          <p>Go back to your cart to make changes.</p>
          <Link to="/shop/cart">Cart</Link>
        </div>
      </div>
    </OrderCancelDetailsStyled>
  )
}

const OrderCancelDetailsStyled = styled.div`
  padding: 5rem 0;

  .wrapper {
    ${standardWrapper};
  }

  .cancelDetails {
    width: 100%;
    text-align: center;

    p {
      ${bodyCopy};
    }

    a {
      ${buttonOne};
    }
  }
`

export default OrderCancelDetails
